<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'eintracht-mauer',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/eintracht-mauer',
      imageCount: 22,
      imageFileFormat: '.jpg',
      name: 'Neue Mauer und neuer Gehweg im Eintrachter Turnverein',
      description: 'Die alte marode Mauer, einschließlich das Gartenhaus des Nachbars, wurde abgerissen und die alten Waschbetonplatten am Gehweg entfernt. Nach mühsamer Erd- und Vorbereitungsarbeiten war es nun möglich, ein stabiles Betonfundament auszuschalen und zu gießen. Darauf wurde mit Bimssteinen gemauert und es entstand eine Mauer mit insgesamt 40 m Länge und 160-210 cm Höhe. Die Mauer wurde oberflächig und farblich an die bereits sanierte Fassade angepasst. Ein Teil der alten Backsteinmauer konnten wir retten, verschönern und als Denkmal zum Ausdruck bringen. Der Geh- und Fahradweg wurde mit neuen Pflastersteinen verlegt.',
    },
    //
  }),
};
</script>
